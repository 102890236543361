.App-header {
    background-color: #282c34;
    min-height: 100vmin;
    display: flex;
    
    
    overflow: hidden;
  
    color: white;
  
    -webkit-touch-callout: none; /* iOS Safari */
      -webkit-user-select: none; /* Safari */
       -khtml-user-select: none; /* Konqueror HTML */
         -moz-user-select: none; /* Firefox */
          -ms-user-select: none; /* Internet Explorer/Edge */
              user-select: none; /* Non-prefixed version, currently
                                    supported by Chrome and Opera */
}

.navbar {
    display: flex;
    min-height: 4vh;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: #282c34;
}

.navbar-title {
    flex: 1;
    text-align: center;
}

.navbar-title h1 {
    color: white;
    text-decoration: none;
    transition: all 200ms ease-in;
    display: inline-block;
}

.navbar-title h1:hover {
    color: #A9ACA9
}


.navbar-options {
    flex: 4;
    text-align: right;
    align-self: center;

}

.navbar-options a {
    padding-right: 2vw;

    font-size: calc(15px + 1vmin);
    font-weight: 600;
    color: white;
    text-decoration: none;

    transition: all 200ms ease-in;
    transform: scale(1);
    display: inline-block;
}

.navbar-options a:hover:after { 
    transform: scaleX(1); 
} 

.navbar-options a:after {
    display: block;
    content: '';
    border-bottom: solid 3px white;
    transform: scaleX(0);
    transition: transform 250ms ease-in-out;
}
.navbar-options a:after{  transform-origin:  0% 50%; }