
.home-section-one {
    /* background-color: #FAFAFA; */
    min-height: 80vh;
    min-width: 100vw;
    display: flex;
    flex-direction: row;
    justify-content: center;
    color: #282c34;
    background-image:  url("../assets/images/glacier_national_park_highline_trail_one.JPG");
    background-size: cover;
}

.home-section-one-overlay {
    min-height: 80vh;
    min-width: 100vw;
    background-color: rgba(255, 255, 255, 0.75);
    border-bottom: 0px solid#282c34;
    box-shadow: 0px 0px 40px #282c34;
}

.home-section-one-typewriter {
    text-align: center;
    min-height: 80vh;
    font-size: 40px;
    display: inline-flex;
    flex-direction: row;
    align-items: center;
}
.home-section-one-typewriter h3{
    /* display: block ruby; */
    /* flex: 1; */
}

.home-section-two {
    background-color: #FAFAFA;
    min-height: 100vh;
}

.aws-text {
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  
    background-image: radial-gradient( #FAFAFA 50%, #FF9900 50%);
    background-size: 400% 400%;
    background-position:top left;

    font-size: 20;
    font-weight: 700;
    text-decoration: none;
    transition: all 1s ease-in-out;
  }
  .aws-text:hover {
    font-size: 25px;
    background-position: right bottom;

  }

  .clemson-text {
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    background-size: 200% 200%;
    background-image: radial-gradient( #FAFAFA 50%,  #F66733 50%);
    background-size: 400% 400%;
    background-position:top left;
  
    font-size: 20;
    font-weight: 700;
    color: #F66733;
    text-decoration: none;
  
    transition: all 1s ease-in-out;
  }
  .clemson-text:hover {
    font-size: 25px;
    background-position: right bottom;
    color: #522D80;
  }

  .vertical-timeline-date p{
      font-size: 40px;
      font-weight: 800;
      color: black;
  }