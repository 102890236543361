.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}



.App-link {
  color: #61dafb;
}

.App-body {

}
.header-attributes { 
  margin-top: 25px;
  font-family: Comic Sans MS;
  font-size: 14px;
  font-style: italic;
  color: white;
}
.header-big-title {
  margin-top: 11vh;
  margin-bottom: 6vh;
}

.App-body p{ 
  font-size: calc(10px + 5vmin);
  
}
.Name-Header {
  font-size: calc(10px + 9vmin);
  font-weight: 600;
  flex: 10;
  min-width: 100vw;
}


.contact-info-container{
  background-color: #738290;
  min-height: 100vh;
  min-width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  
  color: #FAFAFA
}
.experience-container{
  background-color: #A1B5D8;
  min-height: 100vh;
  min-width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.experience-resume-container {
  height: 75vh;
}
.experience-resume-container img{
  height: 33vh;
  box-shadow: 0px 0px 5px 1px black;

  transition: all 1s ease-in-out;
}
.experience-resume-container img:hover{
  height: 88vh;
  box-shadow: 0px 0px 10px 3px black;
}

