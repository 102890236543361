

.travel-page-root { 
    display: flex;
    flex-direction:  column;
    height: 100vh;
    
}

.google-maps-container {
    
}

.travel-navbar-container {
    display: inline-grid;
    height: 40px;
    align-content: center;
    background-color: #282c34;
    /* padding-left: 24px;
    padding-right: 24px;
    margin-left */

    color: white;
    font: white;
}
.MuiBreadcrumbs-separator {
    color: white;
}
.travel-navbar-container a {
    text-decoration: none;
    font-size: 18px;
    color: white;
    font: white;
}

.travel-content-container {
    display: flex;
    flex: 1;
    margin-top: 20px;
}

.travel-card-media {
      
}